import React, { useRef, useState, useEffect } from 'react'
import { graphql, useStaticQuery, Link } from 'gatsby'
import styled from 'styled-components'
import { useSpring, animated } from 'react-spring'

import FacebookLogo from '../../images/facebook.svg'
import InstaLogo from '../../images/instagram.svg'
import TwitterLogo from '../../images/twitter.svg'
import LinkedinLogo from '../../images/linkedin.svg'

import CopyToClipboard from '../../helpers/copy-clipboard'
import Form from './form.js'

// import GetCracking from '../get-cracking'

export const fragment = graphql`
  fragment ContactContentFragment on WordPress_Page_Contactfields {
    contactInfos {
      address
      phone
      email
      directionLink
      directionText
      facebookLink
      instagramLink
      linkedinLink
      twitterLink
    }
  }
`

const ContactContent = ({ data, content }) => {
  const { wordPress } = useStaticQuery(
    graphql`
      query {
        wordPress {
          globalOptions {
            optionFields {
              contactInfos {
                labelLondon
                labelNyc
                address
                addressNyc
                directionLink
                directionText
                email
                emailNyc
                facebookLink
                instagramLink
                linkedinLink
                phone
                phoneNyc
                twitterLink
              }
            }
          }
        }
      }
    `
  )

  const { contactInfos } = wordPress.globalOptions.optionFields

  const canvasInput = useRef(null)
  const canvasOutput = useRef(null)

  const points = []
  let isPointsAdded = false

  const updateCanvasDimentions = () => {
    canvasInput.current.width = window.innerWidth
    canvasInput.current.height = window.innerHeight

    canvasOutput.current.width = window.innerWidth
    canvasOutput.current.height = window.innerHeight
  }

  const calculateMidPoint = (p1, p2) => ({
    x: p1.x + (p2.x - p1.x) / 2,
    y: p1.y + (p2.y - p1.y) / 2
  })

  const drawCanvas = () => {
    if (!canvasInput.current) {
      return
    }

    const contextInput = canvasInput.current.getContext('2d')
    const contextOutput = canvasOutput.current.getContext('2d')

    const gradient = contextOutput.createLinearGradient(3000.0, 1060.0, 0.0, 1940.0)
    gradient.addColorStop(0.028, 'rgba(7, 212, 205, 1.000)')
    gradient.addColorStop(0.337, 'rgba(245, 87, 208, 1.000)')
    gradient.addColorStop(0.611, 'rgba(116, 146, 255, 1.000)')
    gradient.addColorStop(0.785, 'rgba(7, 212, 205, 1.000)')
    gradient.addColorStop(1.0, 'rgba(255, 38, 240, 1.000)')

    contextInput.clearRect(0, 0, window.innerWidth, window.innerHeight)
    contextInput.beginPath()
    contextInput.strokeStyle = gradient
    contextInput.lineWidth = 100
    contextInput.lineJoin = contextInput.lineCap = 'round'

    contextInput.clearRect(0, 0, window.innerWidth, window.innerHeight)

    let p1 = points[0]
    let p2 = points[1]

    contextInput.moveTo(p1.x, p1.y)

    for (let i = 1, len = points.length; i < len; i++) {
      // we pick the point between pi+1 & pi+2 as the
      // end point and p1 as our control point
      const midPoint = calculateMidPoint(p1, p2)
      contextInput.quadraticCurveTo(p1.x, p1.y, midPoint.x, midPoint.y)
      p1 = points[i]
      p2 = points[i + 1]
    }
    // Draw last line as a straight line while
    // we wait for the next point to be able to calculate
    // the bezier control point
    // contextInput.lineTo(p1.x, p1.y)
    contextInput.stroke()
    contextOutput.clearRect(0, 0, window.innerWidth, window.innerHeight)
    contextOutput.globalCompositeOperation = 'destination-in'
    // contextOutput.drawImage(background, 0, 0)
    contextOutput.globalCompositeOperation = 'source-over'

    if (isPointsAdded === true) {
      startAnimate()
    }
  }

  const startAnimate = () => {
    let modToggle = 0
    if (modToggle++ % 4 === 0) {
      requestAnimationFrame(() => {
        drawCanvas()
      })
    }
  }

  const addPoints = e => {
    if (points.length > 2 && !isPointsAdded) {
      isPointsAdded = true
      startAnimate()
    }
    points.push({ x: e.clientX, y: e.clientY })
    if (points.length > 20) {
      points.shift()
    }
  }

  useEffect(() => {
    if (typeof window !== `undefined`) {
      setIsDesktop(window.innerWidth > 1024)
    }

    updateCanvasDimentions()
    window.addEventListener('mousemove', addPoints)
    window.addEventListener('resize', updateCanvasDimentions)

    return function cleanup() {
      window.removeEventListener('mousemove', addPoints)
      window.removeEventListener('resize', updateCanvasDimentions)
    }
  }, [addPoints])

  const [isDesktop, setIsDesktop] = useState()

  const adressAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 500,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const emailAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 600,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const phoneAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 700,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const mapAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 800,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const socialAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 900,
    config: { mass: 5, tension: 500, friction: 100 }
  })
  
  const faqsAnimation = useSpring({
    from: { opacity: 0, transform: `translate3d(0px,50px,0px)` },
    to: { opacity: 1, transform: `translate3d(0px,0px,0px)` },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const socialMobileAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1200,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  const wrapFormAnimation = useSpring({
    from: { opacity: 0 },
    to: { opacity: 1 },
    delay: 1000,
    config: { mass: 5, tension: 500, friction: 100 }
  })

  return (
    <Outer>
      <Canvas ref={canvasInput} className="input" />
      <Canvas ref={canvasOutput} className="output" />

      <WrapContact>
        <WrapContactText>
          <WrapDetails>
            <animated.h4 style={adressAnimation}>{contactInfos.labelLondon}</animated.h4>
            <animated.h3 style={adressAnimation}>{contactInfos.address}</animated.h3>
            <animated.p style={emailAnimation} x-ms-format-detection="none">
              <a href={`tel:${contactInfos.phone}`} x-ms-format-detection="none">
                {contactInfos.phone}
              </a>
            </animated.p>
          </WrapDetails>

          <WrapDetails>
            <animated.h4 style={adressAnimation}>{contactInfos.labelNyc}</animated.h4>
            <animated.h3 style={adressAnimation}>{contactInfos.addressNyc}</animated.h3>
            <animated.p style={emailAnimation} x-ms-format-detection="none">
              <a href={`tel:${contactInfos.phoneNyc}`} x-ms-format-detection="none">
                {contactInfos.phoneNyc}
              </a>
            </animated.p>
          </WrapDetails>
          <WrapEmail style={phoneAnimation}>
            <CopyToClipboard text={contactInfos.email} />
          </WrapEmail>
        </WrapContactText>
        <WrapSocials style={socialAnimation}>
          <a href={contactInfos.twitterLink} target="_blank" rel="noopener noreferrer">
            <TwitterLogo />
          </a>
          <a href={contactInfos.instagramLink} target="_blank" rel="noopener noreferrer">
            <InstaLogo />
          </a>
          <a href={contactInfos.facebookLink} target="_blank" rel="noopener noreferrer">
            <FacebookLogo />
          </a>
          <a href={contactInfos.linkedinLink} target="_blank" rel="noopener noreferrer">
            <LinkedinLogo />
          </a>
        </WrapSocials>
      </WrapContact>
      <WrapFaqs style={faqsAnimation}>
        <p>Have a quick question you need answering? Check out - <Link to={`/faqs`}>FAQ's : Working with KOTA</Link></p>
      </WrapFaqs>
      <WrapSocialsMobile style={socialMobileAnimation}>
        <a href={contactInfos.twitterLink} target="_blank" rel="noopener noreferrer">
          <TwitterLogo />
        </a>
        <a href={contactInfos.instagramLink} target="_blank" rel="noopener noreferrer">
          <InstaLogo />
        </a>
        <a href={contactInfos.facebookLink} target="_blank" rel="noopener noreferrer">
          <FacebookLogo />
        </a>
        <a href={contactInfos.linkedinLink} target="_blank" rel="noopener noreferrer">
          <LinkedinLogo />
        </a>
      </WrapSocialsMobile>
      <WrapForm style={wrapFormAnimation}>
        <Form />
        {isDesktop ? <Blur /> : ''}
      </WrapForm>
    </Outer>
  )
}

const Outer = styled.div`
  position: relative;
  margin: 0 auto;
  cursor: crosshair;
  height: auto;
  min-height: 100vh;
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;

  @media (max-width: 1024px) {
    cursor: initial;
    overflow: visible;
  }

  @media (max-width: 650px) {
    height: auto;
    padding-top: 14rem;
  }
`

const Canvas = styled.canvas`
  position: fixed;
  top: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  display: block;

  @media (max-width: 1024px) {
    display: none;
  }
`

const WrapContact = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  z-index: 10;

  @media (max-width: 1180px) {
    margin-top: 20rem;
    position: relative;
    transform: translate(0, 0);
    top: 0;
    left: 0;
  }

  @media (max-width: 650px) {
    margin-top: 6.5rem;
    position: relative;
    top: initial;
    left: initial;
    transform: translate(0);
  }
`

const WrapForm = styled(animated.div)`
  position: absolute;
  width: calc(40% - 10rem);
  top: 50%;
  right: 10%;
  transform: translateY(-39%);
  padding: 0 5rem 6.5rem 5rem;
  z-index: 50;

  @media (min-width: 1920px) {
    transform: translateY(-41%);
  }

  @media (max-width: 1281px) {
    width: calc(40% - 5rem);
    padding: 0 0 6.5rem 5rem;
  }

  @media (max-width: 1180px) {
    position: relative;
    width: 100%;
    transform: translateY(0);
    right: unset;
    top: unset;
    padding: 0 16%;
    margin: 7.5% 0 20% 0;
    border: 0;
  }

  @media (max-width: 650px) {
    position: relative;
    width: 100%;
    transform: translateY(0);
    right: unset;
    top: unset;
    padding: 0 25px 0 25px;
    margin: 8% 0 20rem 0;
    z-index: 0;
  }

  input {
    padding: 10px 0 10px 5px;
  }

  section {
    padding-top: 2rem;
  }

  .container {
    max-width: 100%;
  }
`

const Blur = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(100%) blur(20px);
`

const WrapContactText = styled.div`
  width: 60%;
  max-width: 1920px;
  margin: 0;
  height: 100%;
  padding-left: 10%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1180px) {
    width: 100%;
    padding: 0 16%;
    margin: 0 auto;
  }

  @media (max-width: 650px) {
    padding-left: 25px;
    padding-right: 0;
  }
`

const WrapSocials = styled(animated.div)`
  width: 60%;
  max-width: 1920px;
  margin: 0;
  height: 100%;
  padding-left: 10%;
  display: flex;
  flex-wrap: wrap;

  @media (max-width: 1180px) {
    width: 100%;
    padding: 0 16%;
    margin: 0 auto;
  }

  @media (max-width: 1024px) {
    display: none;
  }

  a {
    margin-left: 1.2rem;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }

    ellipse,
    circle,
    path {
      transition: stroke 0.25s ease-out, stroke 0.25s ease-out;
    }

    &:hover {
      ellipse {
        stroke: transparent;
        fill: ${props => props.theme.colours.pink};
      }

      circle {
        stroke: transparent;
        fill: ${props => props.theme.colours.pink};
      }

      path {
        fill: ${props => props.theme.colours.white};
      }
    }
  }

  svg {
    fill: ${props => props.theme.colours.grey};
    transition: ll 0.3s ease-in-out;
  }
`

const WrapFaqs = styled(animated.div)`
  position: absolute;
  bottom: 6%;
  left: 10%;
  margin: 5rem 0 0 0;
  display: block;
  z-index: 2;
  
  @media (max-width: 1180px) {
    position: relative;
    display: block;
    margin: 3rem 0;
    padding-left: 6%;
  }
  
  @media (max-width: 1024px) {
    display: none;
  }
  
  p {
    font-size: 2rem;
    color: ${props => props.theme.colours.grey};
  }

  a {
    text-decoration: underline;
    font-weight: 700;

    &:hover {
      text-decoration: none;
    }
  }
`

const WrapSocialsMobile = styled(animated.div)`
  display: none;

  @media (max-width: 1024px) {
    position: relative;
    display: block;
    margin: 3rem 0;
    padding-left: 16%;
    z-index: 0;
  }

  @media (max-width: 650px) {
    padding-left: 25px;
  }

  a {
    margin-left: 1.2rem;
    display: inline-block;

    &:first-child {
      margin-left: 0;
    }

    ellipse,
    circle,
    path {
      transition: stroke 0.25s ease-out, stroke 0.25s ease-out;
    }

    &:hover {
      ellipse {
        stroke: transparent;
        fill: ${props => props.theme.colours.pink};
      }

      circle {
        stroke: transparent;
        fill: ${props => props.theme.colours.pink};
      }

      path {
        fill: ${props => props.theme.colours.white};
      }
    }
  }

  svg {
    fill: ${props => props.theme.colours.grey};
    transition: ll 0.3s ease-in-out;
  }
`

const WrapDetails = styled.div`
  width: 40%;
  margin-left: 5%;

  &:first-child {
    margin-left: 0;
  }

  @media (max-width: 1024px) {
    width: 45%;
    margin-left: 10%;

    &:first-child {
      margin-left: 0;
    }
  }

  @media (max-width: 650px) {
    width: 48.5%;
    margin-left: 3%;
    margin-bottom: 10%;

    &:last-child {
      margin-bottom: 0;
    }
  }

  h4 {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 4.5rem;
    line-height: 1.25;
    letter-spacing: -0.075rem;
    max-width: 100%;
    margin-bottom: 3rem;
    white-space: pre-wrap;

    @media (max-width: 1024px) {
      font-size: 5rem;
      margin-bottom: 3rem;
    }

    @media (max-width: 650px) {
      max-width: 100%;
      margin-bottom: 2rem;
      font-size: 4rem;
    }
  }

  h3 {
    color: ${props => props.theme.colours.grey};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.4rem;
    line-height: 1.25;
    letter-spacing: -0.075rem;
    max-width: 100%;
    margin-bottom: 3rem;
    white-space: pre-wrap;

    @media (max-width: 1024px) {
      font-size: 2.8rem;
      margin-bottom: 4rem;
    }

    @media (max-width: 650px) {
      max-width: 100%;
      font-size: 2.3rem;
    }
  }

  p {
    color: ${props => props.theme.colours.pink};
    font-family: ${props => props.theme.fonts.sofia};
    font-size: 2.4rem;
    line-height: 0.9;

    @media (max-width: 1024px) {
      font-size: 3rem;
    }

    @media (max-width: 650px) {
      font-size: 2.3rem;
    }

    a {
      color: ${props => props.theme.colours.pink};
      cursor: pointer;
      transition: color 0.25s ease-out;

      &:hover {
        color: ${props => props.theme.colours.lightGrey};
      }
    }
  }
`

const WrapEmail = styled(animated.div)`
  div div div {
    color: ${props => props.theme.colours.pink} !important;
    font-family: ${props => props.theme.fonts.sofia};
    line-height: 0.9;
    text-decoration: none;
    margin-top: 3.2rem;
    transition: color 0.25s ease-out;

    &:hover {
      color: ${props => props.theme.colours.lightGrey} !important;
    }

    @media (max-width: 1024px) {
      font-size: 3rem !important;
    }

    @media (max-width: 650px) {
      font-size: 4rem !important;
      margin-top: 4rem;
    }

    h6 {
      color: ${props => props.theme.colours.pink} !important;
      font-size: 1.4rem !important;
    }
  }
`

export default ContactContent
